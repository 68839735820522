.container {
  position: fixed;
  bottom: 3%;
  right: 2%;
  z-index: 1000;
}

.chatIconContainer {
  background-color: green;
  padding: 1rem;
  width: 60px;
  height: 60px;
  /* aspect-ratio: 1 / 1; */
  border-radius: 100px;
  margin-left: auto;
  cursor: pointer;

  box-shadow: 3px 6px 52px 2px rgba(0, 0, 0, 0.45);
  -webkit-box-shadow: 3px 6px 52px 2px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 3px 6px 52px 2px rgba(0, 0, 0, 0.45);
}

.chatIcon {
  width: 100% !important;
  height: 100% !important;
  color: white;
}

.chatWindow {
  background-color: white;
  margin-bottom: 1rem;
  width: 300px;
  height: 500px;
  border-radius: 15px;
  display: flex;
  flex-flow: column;
}

.hidden {
  display: none;
}

.header {
  width: 100%;
  height: 20%;
  color: white;
  text-align: center;
  background-color: green;
  border-radius: inherit;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.header.chatWindowHead {
  flex-flow: row;
  align-items: center;
  padding-left: 0.5rem;
}

.chatWindowHead svg {
  cursor: pointer;
}

.headerContent {
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: baseline;
  padding-left: 1rem;
}

.header h2 {
  font-size: 1.2rem;
  font-weight: 600;
}

.header p {
  color: rgba(255, 255, 255, 0.746);
}

.messages {
  flex: 1;
  width: 100%;
  padding: 1rem;
  justify-content: flex-end;
  overflow-y: scroll;
  /* height: 200px; */
}

.message:first-child {
  margin-top: auto;
}

.message {
  width: 60%;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.message.user {
  margin-left: auto;
  text-align: right;
  background-color: rgba(0, 128, 0, 0.17);
}

.message.server {
  margin-right: auto;
  text-align: left;
  background-color: rgba(124, 124, 124, 0.17);
}

.inputContainer {
  background-color: rgb(241, 241, 241);
  border-radius: 0 0 15px 15px;
  padding: 0.5rem 1rem;
  display: flex;
}

.inputContainer input {
  flex: 1;
  background-color: transparent;
}

.userList {
  padding-top: 0;
}

.user {
  padding: 1rem 1rem;
  border-bottom: 1px solid rgb(201, 201, 201);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.arrowIcon {
  width: 15px !important;
}
