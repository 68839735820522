.cardlisting {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0px;
  padding-top: 2rem;
  justify-content: center;
  align-items: stretch;
  gap: 30px;
  max-width: 1500px;
  margin: auto;
}
