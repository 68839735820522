.container {
  padding-top: 3rem;
}

.mainContainer {
  display: flex;
  margin: 0 auto;
  max-width: 1500px;
  padding: 3rem;
  padding-top: 3rem;
}

.sidebar {
  width: 300px;
}

.main {
  flex: 1;
  display: flex;
  flex-flow: column;
  gap: 2rem;
}

.search {
  max-width: 1500px;
  padding: 0 3rem;
  margin: auto;
  display: flex;
}

.search input {
  flex: 1;
  border-radius: 5px 0 0 5px;
  border: 1px solid rgba(0, 0, 0, 0.589);
  border-right: 0;
  padding: 0.5rem;
  outline: none;
  font-size: 1.1rem;
}

.search button {
  background-color: rgba(0, 101, 0, 0.801);
  color: white;
  border: none;
  /* border-radius: 5px; */
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.2s linear;
  font-size: 1.2rem;
  border-radius: 0 5px 5px 0;
}

.filter {
  color: rgb(111, 111, 111);
}

.filter h3 {
  margin-top: 1rem;
  margin-bottom: 0.2rem;
  color: rgb(56, 56, 56);
}

.filter ul {
  list-style: none;
}

.filter li {
  cursor: pointer;
}

.filter .price {
  display: flex;
  gap: 0.3rem;
}

.filter .price input {
  width: 30%;
}

.filter .price button {
  border: 1px solid rgb(112, 112, 112);
  padding: 0 0.3rem;
  background-color: rgba(255, 255, 255, 0.114);
  cursor: pointer;
  border-radius: 2px;
}

.filter li + li {
  margin-top: 0.2rem;
}

.filter input[type="radio"] {
  margin-right: 0.2rem;
}
