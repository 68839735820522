.container {
  padding: 1rem 2.5rem;
}

.container h2 {
  font-size: 2rem;
  font-weight: 700;
  color: black;
}

.selection {
  width: 100%;
  display: flex;
  gap: 1rem;
  margin: 1.5rem 0;
}

.selection input {
  flex: 1;
  padding: 1rem 1.5rem;
  outline: none;
  border: 2px solid green;
  border-radius: 10px;
}

.selection select {
  padding: 1rem 1.5rem;
  background-color: transparent;
  border: 2px solid green;
  border-radius: 10px;
  /* appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-overflow: "";
  padding-right: calc(1.5rem + 20px);
  position: relative; */
}

.quesList {
  border: 2px solid green;
  border-radius: 10px;
}

.question {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 2px solid green;
}

.quesCont:last-child .question {
  border-bottom: none;
}

.quesCont:last-child.open .question {
  border-bottom: 2px solid green;
}

.open .question {
  border-bottom: 2px solid green;
}

.question svg {
  transform: translateY(50%) rotate(180deg);
  transition: 0.25s;
}

.open .question svg {
  transform: translateY(50%);
}

.answers {
  /* display: none; */
  height: 0;
  overflow: hidden;
  padding: 0;
  border-bottom: 2px solid transparent;
  transition: 0.25s;
}

.open .answers {
  /* display: block; */
  height: 100%;
  padding: 1rem;
  border-bottom: 2px solid green;
}

.quesCont:last-child .answers {
  border-bottom: none;
}

.ansCont {
  margin-bottom: 1rem;
}

.ansCont:last-child {
  margin-bottom: 0;
}

.ansHeader {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
}

/* .selection select::-ms-expand {
  display: none;
} */

.ask {
  margin-top: 2rem;
}

.ask h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.ask input,
textarea {
  display: block;
  width: 100%;
  border: 2px solid green;
  margin-bottom: 1rem;
  padding: 1rem 1.5rem;
  border-radius: 10px;
}

.ask button {
  background-color: green;
  color: white;
  padding: 1rem 3rem;
  border-radius: 10px;
  font-size: 1.1rem;
}
