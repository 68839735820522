.container {
  margin: 0 20px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  position: relative;
}
.container1 {
  margin: 0 20px;
  padding: 0 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  position: relative;
}

.navbar {
  height: 60px;
  background-color: white;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 999;
}

.leftName {
  color: black;
  font-size: x-large;
  font-weight: bold;
}
.leftName1 {
  color: black;
  font-size: larger;
  font-weight: bold;
}

.menu-icon {
  display: none;
}

.nav-elements {
  /* height: 100%; */
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  font-weight: 500;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
  margin-top: 5px;
}
.nav-elements ul li {
  cursor: pointer;
}
.Login {
  margin-top: 5px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #2f234f;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
  padding: 20px;
}
.button {
  border: none;
  background-color: black;
  color: white;
  padding: 10px 20px;
  letter-spacing: 3px;
  font-size: small;
  font-weight: 400;
  cursor: pointer;
  border: 2px solid black;
}
.button:hover {
  background-color: white;
  color: black;
}
.button1 {
  background-color: green;
  color: white;
  padding: 10px 20px;
  letter-spacing: 2px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: -50px;
    top: 60px;
    background-color: white;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }
  .hel {
    height: 100vh;
    width: 100%;
    background-color: black;
    background: rgba(0, 0, 0, 0.5);
  }
  .nav-elements.active {
    width: 320px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px 20px;
  }

  .nav-elements ul li {
    margin-right: unset;
    /* margin-top: 22px; */
  }
}
.pos {
  position: absolute;
  margin: auto;
  top: 50%;
}
/* .positionfixer{
position: absolute;
top: 15px;
right: 10px;
}
.positionabsolute{
  position: relative;
}
@media(min-width:300px) and (max-width:768px) {
 
  .positionfixer{
  position: absolute;
  top: 15px;
  right: 0px;
  }
  .positionabsolute{
    position: relative;
  }
} */
