* {
    margin: 0;
    padding: 0;
  }
  .footer-logo-link {
    color: #070707;
    text-decoration: none;
  }
  .pg-footer {
    font-family: 'Roboto', sans-serif;
  }
  .footer {
      background-color: #fff;
      color: #020202;
      margin-top: 10px;
  }
  .footer-wave-svg {
      background-color: transparent;
      display: block;
      height: 30px;
      position: relative;
      top: -1px;
      width: 100%;
  }
  .footer-wave-path {
      fill: #fffff2;
  }
  .footer-logo h1{
color: #020202;
letter-spacing: 1px;

  }
  
  .footer-content {
      margin-left: auto;
      margin-right: auto;
      display: flex;
      /* max-width: 100%; */
      justify-content:space-between;
      padding: 40px 15px 10px;
      flex-wrap: wrap;
      position: relative;
  }
  
  .side-links{
    display: flex;
    justify-content: center;
    max-width: 100%;
    /* flex-wrap: wrap; */
    gap: 60px;
  }
  .footer-content-column {
      box-sizing: border-box;
      float: left;
      /* border-left: 0.3px solid rgb(211, 208, 208); */
      padding-left: 15px;
      padding-right: 15px;
      width:max-content;
      color: #a22222;
  }
  
  .footer-content-column ul li a {
    color: rgb(80, 79, 79);
    text-decoration: none;
  }
  
  .footer-logo-link {
      display: inline-block;
  }
  .footer-menu {
      margin-top: 30px;
  }
  
  .footer-menu-name {
      color: #080808;
      font-size: 15px;
    
      font-weight: 900;
      letter-spacing: .2em;
      line-height: 18px;
      margin-bottom: 0;
      margin-top: 0;
      text-transform: uppercase;
  }
  .footer-menu-list {
      list-style: none;
      margin-bottom: 0;
      margin-top: 10px;
      padding-left: 0;
  }
  .footer-menu-list li {
      margin-top: 5px;
  }
  
  .footer-call-to-action-description {
      color: #fffff2;
      margin-top: 10px;
      margin-bottom: 20px;
  }
  .button:last-of-type {
      margin-right: 0;
  }
  .footer-call-to-action-button {
  padding: 10px 25px;
  border: none;
  border-radius: 5px;
  color: green;
  color: white;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: medium;
  background-color: green;
  }
  .Login-button{
    background-color: white;
    border: 1px solid green;
    color: black;
    padding: 10px 38px;
  }
  .footer-call-to-action {
      margin-top: 30px;
  }
  .footer-call-to-action-title {
      color: #fffff2;
      font-size: 14px;
      font-weight: 900;
      letter-spacing: .1em;
      line-height: 18px;
      margin-bottom: 0;
      margin-top: 0;
      text-transform: uppercase;
  }
  .footer-call-to-action-link-wrapper {
      margin-bottom: 0;
      margin-top: 10px;
      color: #fff;
      text-decoration: none;
  }
  .footer-call-to-action-link-wrapper a {
      color: #fff;
      text-decoration: none;
  }

  .hidden-link-text {
      position: absolute;
      clip: rect(1px 1px 1px 1px);
      clip: rect(1px,1px,1px,1px);
      -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
      clip-path: inset(0px 0px 99.9% 99.9%);
      overflow: hidden;
      height: 1px;
      width: 1px;
      padding: 0;
      border: 0;
      top: 50%;
  }

  .footer-copyright {
      background-color: #fff;
      color: #2c2c2c;
      /* border-top: 0.6px solid rgb(177, 177, 177); */
      padding: 5px 20px;
    text-align: center;
  }
  
  .footer-copyright-wrapper {
      margin-left: auto;
      margin-right: auto;
      display: flex;
      /* width: 80%; */
      padding: 5px;
      border-top: 0.6px solid rgb(177, 177, 177);
      height: 100%;
      justify-content: space-between;
      width: 100%;
      align-items: center;
  }
  .icons-holder{
    width:38px;
    height:38px;
    background-color: inherit;
    border-radius: 50%;
  }
  .icons-holder img{
    height: 100%;
    width: 100%;
  }
  .icons-right{
    display: flex;
    gap: 19px;
    justify-content: center;
    align-items: center;

  }
  
  .footer-copyright-text {
    color: #777676;
      font-size: 13px;
      letter-spacing: 1px;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 0;
      margin-top: 0;
  }
  
 

  /* Media Query For different screens */
  @media (min-width:320px) and (max-width:479px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
    .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      flex-direction: column;
      padding: 40px 15px 50px;
      position: relative;
    }
    .side-links{
        flex-wrap: wrap;
    }
  }
  @media (min-width:480px) and (max-width:599px)  { /* smartphones, Android phones, landscape iPhone */
    .footer-content {
      margin-left: auto;
      flex-direction: column;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 50px;
      position: relative;
    }
    .side-links{
        flex-wrap: wrap;
    }
    .icons-holder{
      height: 20px;
      width: 20px;
    }
    .icons-right{
      gap: 5px;
    }
  }
  @media (min-width:600px) and (max-width: 800px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .footer-content {
      margin-left: auto;
      margin-right: auto;
      flex-direction: column;
      max-width: 1230px;
      padding: 40px 15px 50px;
      position: relative;
    }
    .side-links{
        flex-wrap: wrap;
    }
  }
  @media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */
  
  }
  @media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */
  
  }
  @media (min-width:1281px) { /* hi-res laptops and desktops */
  
  }

  @media (min-width: 568px) {
    /* .footer-content-column {
        width: 49.99%;
    } */
  }
  