.green{
  background-color: green;
}
  .left {
    float: left;
    width: 20%;
    background-color:green;
    height:90vh;
  }
  .right {
    width: 80%;
    height:90vh;
    float:right;
    background-color: green;
  }
  .ContactButton{
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    background: rgb(1, 95, 1);
    border:1px solid white;
    color: white;
    font-weight: 500;
    font-size: large;
    border-radius: 5px;
    
  }
  .ContactButton:hover{
    background-color: white;
    border-color: green;
    color: black;
    transition:0.2s ease-in;
    cursor: pointer;
  }


.LeftSideContent{
    /* display:flex;  */
    justify-content: center;
    margin: 0.5em;
    color: white;
   }
.SideContent{
    display: flex;
    justify-content: flex-start;
    margin: 2em;
    color: white;
}
.SideContent span{
  margin-right: 5px;
  margin-top: -4px;
}

@media(min-width:500px) and (max-width:1000px){
  .left{
    width: 30%;
  }
  .right{
    width: 70%;
  }
}

@media(max-width:500px){
   .left{
    width: 45%;
   }
   .right{
    width: 55%;
   }
} 
/* @media(min-width:800px) and (max-width:1000px){
  .left{
    width: 40%;
  }
} */

